import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useComplainList() {
    const headers = [
        { text: '投诉单号', value: 'no' },
        { text: '问题分类', value: 'type' },
        { text: '提交日期', value: 'create_at' },
        { text: '提交人昵称', value: 'nickname' },
        { text: '提交人姓名', value: 'real_name' },
        { text: '具体内容', value: 'content' },
        { text: '状态', value: 'state' },
        { text: '回复内容', value: 'reply_content' },
        { text: '回复时间', value: 'reply_at' },
        { text: '备注', value: 'desc' },
        { text: '创建时间', value: 'create_at' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false,
        dialogIgnore: {
            open: false,
            loading: false,
            item: {},
            content: {}
        },
        dialogReply: {
            open: false,
            loading: false,
            item: {},
            content: {}
        }
    })
    // DRIVER=司机  VEHICLE=车辆  ORDER=订单  COMPANY=公司  OTHER=其他
    const enums = {
        status: { "1": "已回复", "2": "已忽略", "99": "待回复", },
        statusColor: { "1": "success", "2": "warning", "99": "info", },
        types: { "DRIVER": "投诉司机", "VEHICLE": "投诉车辆", "ORDER": "投诉订单", "COMPANY": "投诉公司", "OTHER": "其他投诉", },
        typesColor: { "DRIVER": "success", "VEHICLE": "info", "ORDER": "warning", "COMPANY": "error", "OTHER": "", },
    }
    const fetchComplainList = () => {
        table.value.loading = true
        store.dispatch('app-user-complain-list/fetchComplainList', param.value).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.loading = false)
    }

    const toIgnore = (item) => {
        table.value.dialogIgnore = {
            open: true,
            loading: false,
            item: item,
            content: { id: item.id }
        }
    }
    const toReply = (item) => {
        table.value.dialogReply = {
            open: true,
            loading: false,
            item: item,
            content: { id: item.id, content: '' }
        }
    }
    const ignoreComplain = () => {
        table.value.loading = true
        const data = { ...table.value.dialogIgnore.content }
        store.dispatch('app-user-complain-list/ignoreComplain', data).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogIgnore.open = false
            fetchComplainList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.loading = false)
    }
    const replyComplain = () => {
        table.value.loading = true
        const data = { ...table.value.dialogReply.content }
        store.dispatch('app-user-complain-list/replyComplain', data).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogReply.open = false
            fetchComplainList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchComplainList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        enums,
        toIgnore, toReply, ignoreComplain, replyComplain,
        fetchComplainList
    }
}
