<template>
    <div id='user-complain-list'>
        <v-dialog v-model="table.dialogReply.open" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">回复投诉</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogReply.item.nickname" label="提交人"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogReply.item.type" label="问题分类"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="table.dialogReply.item.content" label="内容"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="table.dialogReply.content.content" label="回复内容" dense
                                    hint="请输入回复内容"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" outlined @click="table.dialogReply.open = false"> 取消 </v-btn>
                    <v-btn color="primary" @click="replyComplain()"> 回复 </v-btn> </v-card-actions> </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogIgnore.open" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">忽略投诉</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogIgnore.item.nickname" label="提交人"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogIgnore.item.type" label="问题分类"
                                    dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="table.dialogIgnore.item.content" label="内容"
                                    dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" outlined @click="table.dialogIgnore.open = false"> 取消 </v-btn>
                    <v-btn color="warning" @click="ignoreComplain()"> 忽略 </v-btn> </v-card-actions> </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <v-text-field v-model='param.query' class='user-search me-3 mb-4' dense hide-details outlined
                    placeholder='搜索'></v-text-field>
                <v-spacer></v-spacer>
            </v-card-text>
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total' show-select>
                <template #[`item.state`]='{ item }'>
                    <v-chip :color="enums.statusColor[item.state]" small>{{ enums.status[item.state] }}</v-chip>
                </template>
                <template #[`item.type`]='{ item }'>
                    <v-chip :color="enums.typesColor[item.type]" small>{{ enums.types[item.type] }}</v-chip>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-row dense>
                        <v-col v-if="item.state == 99">
                            <v-btn @click="toIgnore(item)" color="warning" small>忽略 </v-btn>
                        </v-col>
                        <v-col v-if="item.state == 99">
                            <v-btn @click="toReply(item)" color="error" dark small>回复 </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import complainStoreModule from '../complainStoreModule'
import useComplainList from './useComplainList'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user-complain-list'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, complainStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, toIgnore, toReply, ignoreComplain, replyComplain, fetchComplainList } = useComplainList()

        return {
            headers,
            param,
            table,
            enums,
            toIgnore, toReply, ignoreComplain, replyComplain,
            fetchComplainList,
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
